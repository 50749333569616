<template>
  <div>
    <toolbar></toolbar>

    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    
    <Footer></Footer>
    
    <scroll-to-top></scroll-to-top>

  </div>
</template>

<script>
import Toolbar from '@/components/ui/landing/toolbar/Toolbar.vue'
import Footer from '@/components/ui/landing/footer/Footer.vue'
import ScrollToTop from '@/components/navigation/ScrollToTop.vue'
export default {
  components: {
    Toolbar,
    Footer,
    ScrollToTop
  }
}
</script>