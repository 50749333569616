<template>
  <v-card>
    <v-navigation-drawer v-model="drawer" app temporary>

      <div class="pa-3">
        <router-link to="/">
          <v-img
            :src="require('@/assets/images/ha_logo.jpg')"
          />
        </router-link>
      </div>
      
      <v-list
        v-for="(item, i) in menu"
        :key="i"
      >
        <v-list-item
          v-if="!item.children"
          :to="item.link"
          :href="item.href"
          :target="item.target"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="item.children"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <div>
            <div class="px-3">
              <v-list-item
                v-for="(child, k) in item.children"
                :key="k"
                :to="child.link"
                :href="child.href"
                :target="child.target"
              >
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item>
            </div>
          </div>
        </v-list-group>
        
      </v-list>
    </v-navigation-drawer>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      class="drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>
    <v-app-bar
      v-if="$vuetify.breakpoint.lgAndUp"
      :src="require('@/assets/images/ha_logo.jpg')"
      app
      height="300"
      min-height="70"
      prominent
      elevate-on-scroll
      fade-img-on-scroll
      shrink-on-scroll
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          max-height="200"
          contain
        ></v-img>
      </template>

      <v-container class="fill-height text-center justify-center align-end">
        <v-menu
          v-for="(item, i) in menu"
          :key="i"
          v-model="item.model"
          attach
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :input-value="item.model"
              color="primary"
              text
              tile
              :to="item.link"
              :href="item.href"
              :target="item.target"
              v-on="on"
            >
              <strong
                :class="i + 1 === menu.length ? 'font-weight-black' : ''"
                class="text-capitalize"
                v-text="item.text"
              />
            </v-btn>
          </template>
          <v-card
            v-if="item.children != null"
            color="white"
            tile
          >
            <v-list
              dense
              class="transparent"
            >
              <div>
                <div class="px-3">
                  <v-list-item
                    v-for="(child, k) in item.children"
                    :key="k"
                    :to="child.link"
                    :href="child.href"
                    :target="child.target"
                  >
                    <v-list-item-title><strong>{{ child.text }}</strong></v-list-item-title>
                  </v-list-item>
                </div>
              </div>
            </v-list>
          </v-card>
        </v-menu>
      </v-container>
    </v-app-bar>
  </v-card>
</template>
<script>
import { menu3 as menu } from './menus.js'
export default {
  data() {
    return {
      drawer: null,
      menu
    }
  }
}
</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 60px;
  left: -22px;
  z-index: 6;
}
</style>
